
const url = 'https://api.rifahit.com';
const url_storage = 'https://api.rifahit.com/storage/';

const api = {

    cadastrar: async (nome, email, telefone, password, cpf_cnpj) => {
        const response = await fetch(`${url}/empresas/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                nome: nome,
                email: email,
                telefone: telefone,
                cpf_cnpj: cpf_cnpj,
                password: password
            })
        });
        return response.json();
    },

}

export { url, api, url_storage };