import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';

import './menu.css';

import SemLogo from '../../assets/imgs/menu/semlogo.png';
import Logo from '../../assets/imgs/logos/3.png';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import ModalCadastro from '../modalCadastro/ModalCadastro';

function Menu(){
    const [imagem, setImagem] = useState(Logo);
    const [menu, setMenu] = useState(false);
    const [modalCadastro, setModalCadastro] = useState(false);

    useEffect(() => {

        var links = document.querySelectorAll('header ul li a');

        links.forEach(link => {
            let hash = link.getAttribute('href');
            let id = document.querySelector(hash.replace('/', ''));
            if (id) {
                link.addEventListener('click', function(e){
                    e.preventDefault();
                    id.scrollIntoView({behavior: 'smooth'});
                });
            }
        });

    }, []);

    return(
        <header>
            <Link to='/' className="logo"><img src={imagem} alt="Logo" class="sem-logo"/></Link>
            <ul class="navigation w-100 ps-sm-5 pe-sm-5">
                <li><Link to='#features'>Funcionalidade</Link></li>
                <li><Link to='#perguntas-frequentes'>Perguntas frequentes</Link></li>
                <li><Link to='#saq'>Suporte</Link></li>
                <li class='btn btn-outline-success ms-auto me-sm-3'>Acessar</li>
                <li class='btn btn-success' onClick={() => setModalCadastro(true)}>Criar conta</li>
            </ul>
            {!menu ? <MenuIcon className="toggle-rh" onClick={() => setMenu(!menu)} alt=''></MenuIcon>
            :
            <CloseIcon className="toggle-rh" onClick={() => setMenu(!menu)} alt=''></CloseIcon>}

            <ModalCadastro show={modalCadastro} onHide={() => setModalCadastro(false)}></ModalCadastro>
        </header>
    )
}

window.addEventListener('scroll', function() {
    const header = document.querySelector('header');
    header.classList.toggle('sticky', window.scrollY > 0)
});

export default Menu