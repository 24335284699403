import React, {useState, useRef} from 'react';

import './modalcadastro.css';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import InputMask from 'react-input-mask';
import CryptoJS from 'crypto-js';
import Lottie from 'lottie-react';

import { api } from '../../api/connect';

import EmailIcon from '@mui/icons-material/Email';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import FingerprintIcon from '@mui/icons-material/Fingerprint';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import animationSuccess from '../../assets/imgs/lottie/success.json';
import { PermIdentity } from '@mui/icons-material';

function ModalCadastro(props) {

    const inputname = useRef(null);

    const [title, setTitle] = useState('Cadastre-se!');
    const [visible, setVisible] = useState(false);

    let obrigatorio = {
        "nome": true,
        "telefone": true,
        "email": true,
        "password": true ,
        "cpf": false
    }

    let handlerSubmit = (e) => {

        e.preventDefault();

        let dados = {
            "nome": e.target.nome.value,
            "telefone": e.target.telefone.value,
            "email": e.target.email.value,
            "password": e.target.password.value,
            "cpf": e.target.cpf.value
        }

        // Verifica se password tem mais de 8 caracteres
        if (dados.password.length < 8) {
            toast.error('A senha deve ter no mínimo 8 caracteres', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            return
        }

        // Verifica se password e password_confirm são iguais
        if (dados.password !== e.target.password_confirm.value) {
            toast.error('As senhas não conferem', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            return
        }

        api.cadastrar(dados.nome, dados.email, dados.telefone, CryptoJS.MD5(dados.password).toString(), dados.cpf).then((response) => {

            if (response?.error) {
                toast.error(response.error, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                return
            }

            setTitle('Cadastro realizado com sucesso!')
            setVisible(true)

            executeCallback(true);
            return

        });

    }

    let executeCallback = (arg) => {
        if (props.callback) {
            props.callback(arg);
        }
    }

    return (


        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Form onSubmit={(e) => handlerSubmit(e)} className={visible && 'd-none'}>

                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1"><DriveFileRenameOutlineIcon/></InputGroup.Text>
                        <Form.Control
                        name='nome'
                        placeholder={'Seu nome completo ou o nome da sua empresa' + (obrigatorio.nome ? '' : ' (opcional)')}
                        aria-label="Nome completo"
                        aria-describedby="basic-addon1"
                        required={obrigatorio.nome ? 'Campo obrigatório' : true}
                        ref={inputname}
                        />
                    </InputGroup>

                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1"><LocalPhoneIcon/></InputGroup.Text>
                        <Form.Control
                        name='telefone'
                        placeholder={'Telefone' + (obrigatorio.telefone ? '' : ' (opcional)')}
                        aria-label="Telefone"
                        aria-describedby="basic-addon1"
                        required={obrigatorio.telefone ? 'Campo obrigatório' : false}
                        as={InputMask}
                        mask="(99) 99999-9999"
                        />
                    </InputGroup>

                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1"><EmailIcon/></InputGroup.Text>
                        <Form.Control
                        name='email'
                        placeholder={'Email' + (obrigatorio.email ? '' : ' (opcional)')}
                        aria-label="Email"
                        aria-describedby="basic-addon1"
                        required={obrigatorio.email ? 'Campo obrigatório' : false}
                        />
                    </InputGroup>

                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1"><PermIdentity/></InputGroup.Text>
                        <Form.Control
                        name='cpf'
                        placeholder={'CPF' + (obrigatorio.cpf ? '' : ' (opcional)')}
                        aria-label="CPF"
                        aria-describedby="basic-addon1"
                        required={obrigatorio.cpf ? 'Campo obrigatório' : false}
                        as={InputMask}
                        mask="999.999.999-99"
                        />
                    </InputGroup>

                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1"><FingerprintIcon/></InputGroup.Text>
                        <Form.Control
                        name='password'
                        placeholder={'Senha' + (obrigatorio.password ? '' : ' (opcional)')}
                        aria-label="Password"
                        aria-describedby="basic-addon1"
                        required={obrigatorio.password ? 'Campo obrigatório' : false}
                        type='password'
                        />
                    </InputGroup>

                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1"><FingerprintIcon/></InputGroup.Text>
                        <Form.Control
                        name='password_confirm'
                        placeholder={'Confirme a senha' + (obrigatorio.password ? '' : ' (opcional)')}
                        aria-label="Password"
                        aria-describedby="basic-addon1"
                        required={obrigatorio.password ? 'Campo obrigatório' : false}
                        type='password'
                        />
                    </InputGroup>

                    <div class='d-flex justify-content-end'>
                        <Button className='my-2' variant='success' type='submit'>Cadastrar</Button>
                    </div>

                </Form>

                <div class={'px-3 ' + (!visible && 'd-none')}>

                    <Lottie animationData={animationSuccess} className='w-50 mx-auto mb-4'/>

                    <p class='text-center'><strong class='d-block'>Bem-vindo(a) ao RifaHit!</strong> Prepare-se para uma experiência revolucionária na gestão de rifas.</p>
                        
                    <div>Em breve, estaremos abrindo as portas para novos parceiros. Assim que tudo estiver pronto, entraremos em contato! Fique atento(a) e prepare-se para fazer parte desta jornada emocionante. Mal podemos esperar para trabalhar juntos! 😊🚀🎉</div>

                    <div class='d-flex justify-content-end'>
                        <Button variant='success' className='my-2' type='submit' onClick={() => props.onHide()}>Fechar</Button>
                    </div>

                </div>

            </Modal.Body>

            <ToastContainer />

        </Modal>
    );
}


export default ModalCadastro;
