import React, {useState, useEffect} from 'react'
import { Link, useParams } from 'react-router-dom'

import '../assets/css/home.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import Homem1 from '../assets/imgs/home/homem1.png';
import Homem2 from '../assets/imgs/home/homem2.png';
import ElaboreImg from '../assets/imgs/home/criar.png';
import LancarImg from '../assets/imgs/home/lancar.png';
import MonetizeImg from '../assets/imgs/home/monetize.png';
import PersonalizeImg from '../assets/imgs/home/personalizar.png';
import PersonalizacaoImg from '../assets/imgs/home/personalizacao.png';
import RegistroPagImg from '../assets/imgs/home/registropag.png';
import RankingImg from '../assets/imgs/home/ranking.png';
import FederalImg from '../assets/imgs/home/federal.png';
import ElasticaImg from '../assets/imgs/home/elastica.png';
import LealdadeImg from '../assets/imgs/home/lealdade.png';
import SeuMoneyImg from '../assets/imgs/home/seumoney2.png';
import InfinitoImg from '../assets/imgs/home/infinito.png';
import Custo0Img from '../assets/imgs/home/custo0.png';

import LoadingSplash from '../components/LoadingSplash/LoadingSplash';
import ModalCadastro from '../components/modalCadastro/ModalCadastro';

function Home() {

    const [loading, setLoading] = useState(false);
    const [modalCadastro, setModalCadastro] = useState(false);

    useEffect(() => {

    }, [])

    return(
        <div>

            <section class="tela-1 d-flex justify-content-center text-center align-items-center flex-column primeira-section">

                <div>
                    <h1 class='display-1'>Crie sua rifa de forma rápida e fácil!</h1>
                    <p class='lead mt-3'>A solução mais abrangente para você desenvolver e administrar suas rifa com simplicidade e conveniência visando o seu lucro.</p>
                    <div class="mt-5 mb-5">
                        <div class="btn btn-dark rounded-pill py-3 px-5 d-sm-inline d-block" onClick={() => setModalCadastro(true)}>Iniciar agora!</div>
                        <div class="btn btn-outline-success rounded-pill ms-sm-5 mt-3 mt-sm-0 py-3 px-5 d-sm-inline d-block"><WhatsAppIcon className='me-2'/>Saiba mais</div>
                    </div>
                </div>

                <img src={Homem1} alt="Homem com dinheiro e um celular" class="img-fluid img-rh-1 d-none d-lg-block" />
                <img src={Homem2} alt="Homem com notebook e cafe" class="img-fluid img-rh-2 d-none d-lg-block" />
                <img src={Homem1} alt="Homem com dinheiro e um celular" class="img-fluid d-block d-sm-none w-75 mt-auto" />

            </section>

            <section class="tela-2 d-flex justify-content-center text-center align-items-center">

                <div class="my-5">
                    <h1>Observe a praticidade que é lucrar com a gente</h1>
                    <p class='lead mt-3'>Facilidade e praticidade para criar suas rifas com total tranquilidade.</p>
                    <div class="mt-5 d-flex justify-content-center flex-wrap">

                        <div class='rh-200'>
                            <img src={PersonalizeImg} alt="personalizar" class="img-fluid img-rh-col" />
                            <h5 class='mt-3'>Personalize</h5>
                            <p class=''>Configure o seu ambiente</p>
                        </div>
                        
                        <div class='rh-200'>
                            <img src={ElaboreImg} alt="elaborar" class="img-fluid img-rh-col" />
                            <h5 class='mt-3'>Elabore</h5>
                            <p class=''>Preencha o fórmulario de criação da rifa</p>
                        </div>

                        <div class='rh-200'>
                            <img src={LancarImg} alt="lançar" class="img-fluid img-rh-col" />
                            <h5 class='mt-3'>Lance</h5>
                            <p class=''>Escolha o método de publicação e bote para rodar!</p>
                        </div>

                        <div class='rh-200'>
                            <img src={MonetizeImg} alt="monetizar" class="img-fluid img-rh-col" />
                            <h5 class='mt-3'>Monetize</h5>
                            <p class=''>Todo o valor obtido vai para sua conta!</p>
                        </div>

                    </div>
                </div>

            </section>

            <section id='features' class="tela-1 d-flex justify-content-center text-center align-items-center">

                <div class="my-5">
                    <h1>Explore mais sobre as nossas funcionalidades:</h1>
                    <p class='lead mt-3'>Centralize todas as ferramentas necessárias para criar e gerenciar suas rifas em um único lugar!</p>
                    <div class="mt-5 d-flex justify-content-center flex-wrap">

                        <div class='rh-300'>
                            <img src={PersonalizacaoImg} alt="" class="img-fluid" />
                            <h5 class='mt-3'>Personalização</h5>
                            <p class=''>Destaque-se com um domínio próprio e um site white-label personalizável. Aumente sua presença online com um design arrojado que reflete a identidade da sua empresa.</p>
                        </div>

                        <div class='rh-300'>
                            <img src={RegistroPagImg} alt="pagamentos" class="img-fluid" />
                            <h5 class='mt-3'>Registro de Pagamentos</h5>
                            <p class=''>Com o registro de pagamentos, você tem controle total das colaborações feitas na sua rifa, tudo podendo ser acompanhado em tempo real.</p>
                        </div>

                        <div class='rh-300'>
                            <img src={RankingImg} alt="ranking" class="img-fluid" />
                            <h5 class='mt-3'>Ranking</h5>
                            <p class=''>Possibilita que você possa visualisar e destacar os principais contribuidores da sua rifa.</p>
                        </div>
                        
                        <div class='rh-300'>
                            <img src={FederalImg} alt="Loteria Federal" class="img-fluid" />
                            <h5 class='mt-3'>Loteria Federal</h5>
                            <p class=''>Os resultados da loteria federal são automaticamente extraídos do sistema da Caixa, destacando o vencedor conforme suas regras de seleção</p>
                        </div>

                        <div class='rh-300'>
                            <img src={Custo0Img} alt="Custo Zero" class="img-fluid" />
                            <h5 class='mt-3'>Custo Zero</h5>
                            <p class=''>Oferecemos a opção de pagamento após a realização da rifa, permitindo que você faça rifas sem nenhum investimento inicial.</p>
                        </div>

                        <div class='rh-300'>
                            <img src={InfinitoImg} alt="Números Infinitos" class="img-fluid" />
                            <h5 class='mt-3'>Números Infinitos</h5>
                            <p class=''>Aqui, não temos limites! Você pode escolher quantos números quiser, seja 10 ou 1.000.000.000!</p>
                        </div>

                        <div class='rh-300'>
                            <img src={ElasticaImg} alt="Rifa Elástica" class="img-fluid" />
                            <h5 class='mt-3'>Rifa Elástica</h5>
                            <p class=''>Sua rifa está indo bem? Vendendo mais do que o esperado? Com a rifa elástica, novos números são adicionados automaticamente com o aumento das vendas.</p>
                        </div>

                        <div class='rh-300'>
                            <img src={LealdadeImg} alt="Recompensa por Lealdade" class="img-fluid" />
                            <h5 class='mt-3'>Recompensa por Lealdade</h5>
                            <p class=''>
                                Oferece chances extras no sorteio para os jogadores mais fiéis. Quanto mais números você comprar, mais chances terá de ganhar. É nossa forma de valorizar sua lealdade e tornar sua experiência ainda melhor!
                            </p>
                        </div>

                    </div>
                </div>

            </section>

            <section class="tela-2 d-flex justify-content-center text-center align-items-center">

                <div class="my-5">
                    <img src={SeuMoneyImg} alt="Seu dinheiro" class="img-fluid w-25 mb-5" />
                    <h1>Receba diretamente os pagamentos</h1>
                    <p class='lead mt-3'>Todo o dinheiro arrecadado por suas rifas vai diretamente para você!</p>
                    <div class="mt-4">
                        <div class="btn btn-light rounded-pill" onClick={() => setModalCadastro(true)}>Vamos começar agora mesmo!</div>
                    </div>
                </div>

            </section>

            <section id='saq' class="tela-1 d-flex justify-content-center text-center align-items-center">

                <div class="my-5">
                    <h1>Estamos aqui para ajudar! Conte com nossa equipe de suporte.</h1>
                    <p class='lead mt-3'>Qualquer dúvida, estamos à disposição! Entre em contato conosco pelo WhatsApp.</p>
                    <div class="mt-4">
                        <div class="btn btn-outline-success rounded-pill">Falar com o suporte</div>
                    </div>
                </div>

            </section>

            <section id='perguntas-frequentes' class="tela-2">

                <div class="mt-5 p-5">
                    <h1>Perguntas frequentes</h1>
                    <p class='lead mt-3'>Se você não conseguiu encontrar a sua pergunta, entre em contato conosco através do nosso suporte.</p>
                    <div class="mt-5 row">

                        <div class="col-sm-4 p-3">
                            <h5 class="mb-3">Há alguma taxa associada às compras de números realizadas pelos clientes?</h5>
                            <p class='mb-4'>Não cobramos taxas adicionais além da taxa de publicação da rifa. Além disso, oferecemos a opção de pagamento após a realização da rifa, permitindo que você faça rifas sem nenhum investimento inicial.</p>

                            <h5 class="mb-3">Como recebo os pagamentos dos números da minha rifa?</h5>
                            <p class='mb-4'>Os pagamentos são recebidos diretamente em sua conta bancária. Antes de iniciar suas rifas, é necessário cadastrar a conta que receberá os pagamentos.</p>

                            <h5 class="mb-3">O que acontece com os números não vendidos?</h5>
                            <p>Quando você decide finalizar a rifa e há números não vendidos, o sistema automaticamente os sorteia entre os clientes que já adquiriram números. Quanto mais números um cliente comprar, maior será sua chance de ganhar os números remanescentes.</p>
                        </div>

                        <div class="col-sm-4 p-3">

                            <h5 class="mb-3">Quantas rifas eu posso fazer?</h5>
                            <p class='mb-4'>Você pode fazer quantas rifas achar necessário. Não há limitação quanto a isso.</p>

                            <h5 class="mb-3">Qual é o papel do sistema RifaHit na criação das rifas?</h5>
                            <p>O sistema RifaHit não assume responsabilidade pela criação das rifas, deixando essa incumbência aos organizadores. O sistema se limita a fornecer infraestrutura exclusiva para elaboração de rifas, sem interferir na obtenção de eventuais autorizações requeridas pelo organizador para sua campanha.</p>
                        
                        </div>

                        <div class="col-sm-4 p-3">
                            <h5 class="mb-3">É necessário autorização para iniciar uma rifa?</h5>
                            <p>As regulamentações podem variar dependendo do local onde a rifa será realizada, sendo importante verificar as normas locais antes de organizar o evento. No Brasil, a fiscalização e autorização das rifas são de responsabilidade do SECAP (Secretaria de Avaliação, Planejamento, Energia e Loteria do Ministério da Economia). Para regularizar uma rifa, é necessário seguir critérios específicos e solicitar autorização através do Sistema de Controle de Promoção Comercial (SCPC). Em caso de dúvidas, os canais de atendimento do SCPC estão disponíveis 24 horas por dia, 7 dias por semana, na Central de Atendimento Telefônico 0800-978 2332.</p>
                        </div>

                    </div>
                </div>

            </section>

            <ModalCadastro show={modalCadastro} onHide={() => setModalCadastro(false)}/>

            <LoadingSplash active={loading}/>
            <ToastContainer />

        </div>
    )
}

export default Home