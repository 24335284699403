import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

// pages
import Home from './pages/Home.js';

// components
import Footer from './components/footer/Footer.js';
import Menu from "./components/menu/Menu.js";

function App() {

  return (
    <BrowserRouter>
      <Menu/>
      <Routes>
        <Route path='/' element={<Home/>}></Route>
        <Route path='*' element={<Navigate to='/'/>}></Route>
      </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;